import React from "react";
import Banner from "../CommonComponents/Banner";
import Footer from "../CommonComponents/Footer";
import ContentList from "../BlogPage/ContentList";
import { Helmet } from "react-helmet";

const Blog: React.FC = () => {
  return (
    <div className="mt">
      <Banner title="Blog Section" />
      <Helmet>
        <title>Blog Section : AiJobster</title>
      </Helmet>
      <ContentList />
      <Footer />
    </div>
  );
};

export default Blog;
