import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiBell, FiStar, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import CheckoutButton from "./CheckoutButton";

interface BannerProps {
  title: string;
}

const Banner: React.FC<BannerProps> = ({ title }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSubscription, setModalSubscription] = useState(false);
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const openModalSubscription = () => {
    setModalSubscription(true);
  };

  const closeModalSubscription = () => {
    setModalSubscription(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="dark py-5  text-white">
      <div className="container">
        <h1 className="text-capitalize text-center fw-light">{title}</h1>
      </div>
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        <button
          onClick={openModalSubscription}
          className="btn btn-sm btn-primary rounded m-1"
        >
          <FiStar color="#fff" size={16} className="mx-2" />
          <span>Create Job Alerts</span>
        </button>
        <button
          onClick={openModal}
          className="btn btn-sm btn-primary rounded m-1"
        >
          <FiBell color="#fff" size={16} className="mx-2" />
          <span>Subscribe to Weekly Job Alerts</span>
        </button>
      </div>

      <Modal
        className="subs d-flex"
        show={modalSubscription}
        onHide={closeModalSubscription}
      >
        <Modal.Header className="modal-bg d-flex justify-content-between text-light">
          <Modal.Title>Unlock AI Jobs with AiJobster</Modal.Title>
          <FiX onClick={closeModalSubscription} size={30} />
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="mb-4">
              <div className="ms-3">
                <b className="d-flex align-items-center text-primary">
                  <FiStar color="gold" size={20} className="me-2" />
                  Discover Hidden Jobs{" "}
                </b>
                <p>
                  AIjobster scans the internet every day to find job openings
                  that aren’t posted on traditional job boards like LinkedIn or
                  Indeed.
                </p>
              </div>
              <div className="ms-3">
                <b className="d-flex align-items-center text-primary">
                  <FiStar color="gold" size={20} className="my-2 me-2" />
                  Get Ahead of the Competition
                </b>
                <p>
                  We identify new job listings within 24 hours of being posted,
                  giving you the chance to apply before others even know they’re
                  available. Early applications can help you stand out in a
                  crowded job market.
                </p>
              </div>
              <div className="ms-3">
                <b className="d-flex align-items-center text-primary">
                  <FiStar color="gold" size={20} className="my-2 me-2" />
                  Stay Informed with Daily Updates
                </b>
                <p>
                  Receive curated job alerts directly to your inbox every day,
                  so you never miss out on new opportunities. Our intelligent
                  system ensures you only get the most relevant positions based
                  on your profile and preferences.
                </p>
              </div>
            </div>

            <div>
              <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                <h6 className="text-primary m-0">Weekly Plan</h6>
                <h6 className="text-muted m-0">$2/ Week</h6>
                <CheckoutButton plan="weekly" />
              </div>
              <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                <h6 className="text-primary m-0">Monthly Plan</h6>
                <h6 className="text-muted m-0">$7/ Month</h6>
                <CheckoutButton plan="monthly" />
              </div>

              <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                <h6 className="text-primary m-0">Yearly Plan</h6>
                <h6 className="text-muted m-0">$77/ Year</h6>
                <CheckoutButton plan="yearly" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalIsOpen} onHide={closeModal} centered>
        <Modal.Header className="modal-bg d-flex justify-content-between align-items-center text-light">
          <Modal.Title>Subscribe to our job emails</Modal.Title>
          <FiX onClick={closeModal} />
        </Modal.Header>
        <Modal.Body className="modal-bg d-flex justify-content-center align-items-center">
          <iframe
            title="External Content"
            src="https://tally.so/r/3xd6xE"
            width="320"
            height="400"
            frameBorder="0"
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Banner;
