import React from "react";
import { loadStripe } from "@stripe/stripe-js";

const pKey: string = process.env.REACT_APP_STRIPE_PK || ""; // empty string as fallback
const stripePromise = loadStripe(pKey);

interface CheckoutButtonProps {
  plan: "weekly" | "monthly" | "yearly";
}

const planDetails = {
  weekly: { priceId: "price_1QPScPCeBxTp817DPj5Os0rn", label: "$2 per week" },
  monthly: { priceId: "price_1QPScuCeBxTp817DB0Ifx52K", label: "$7 per month" },
  yearly: { priceId: "price_1QPSdCCeBxTp817D0HvSlTQD", label: "$77 per year" },
};

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ plan }) => {
  const handleClick = async () => {
    const stripe = await stripePromise;

    if (!stripe) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    const { priceId } = planDetails[plan];

    const response = await fetch(
      "https://api.stripe.com/v1/checkout/sessions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SK}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          "payment_method_types[]": "card",
          "line_items[0][price]": priceId,
          "line_items[0][quantity]": "1",
          mode: "subscription",
          success_url: `${window.location.origin}/dashboard?session_id={CHECKOUT_SESSION_ID}`,
          cancel_url: `${window.location.origin}/cancel`,
        }).toString(),
      }
    );

    const session = await response.json();

    if (session.id) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        console.error("Error redirecting to Checkout:", error);
      }
    } else {
      console.error("Error creating Checkout session:", session);
    }
  };

  return (
    <div>
      <button
        role="link"
        onClick={handleClick}
        className="btn btn-primary px-3 btn-sm m-3"
      >
        Pay Now
      </button>
    </div>
  );
};

export default CheckoutButton;
