import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../CommonComponents/Banner";
import {
  FiArrowUp,
  FiArrowUpRight,
  FiBox,
  FiGrid,
  FiMapPin,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Footer from "../CommonComponents/Footer";
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import { Helmet } from "react-helmet";

interface CompanyListing {
  company_name: string;
  company_url: string;
  company_logo: string;
  ai_category: string[];
  employees: string;
  fund: string;
  fund_stage: string;
  hq: string;
  year_founded: number;
  job_count: number;
}

interface Heading {
  heading: string;
}

const Company: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<{
    companies: CompanyListing[];
    headings: Heading[];
  }>({
    companies: [],
    headings: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<{
          companies: CompanyListing[];
          headings: Heading[];
        }>(process.env.REACT_APP_COMPANY_LISTING_API!);

        const filteredHeadings = response.data.headings.filter(
          (heading) => heading.heading !== "Company Url"
        );
        const filteredCompanies = response.data.companies;
        setData({
          companies: filteredCompanies,
          headings: filteredHeadings,
        });
        setLoading(false);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const navigateToDetails = (value: string) => {
    navigate(`/company/${value}`, { state: { value: value } });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredCompanies = data.companies.filter(
    (company) =>
      (selectedCategory === "All" ||
        company.ai_category.includes(selectedCategory)) &&
      (company.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        company.hq.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Get unique AI categories
  const aiCategories = Array.from(
    new Set(data.companies.flatMap((company) => company.ai_category))
  );

  return (
    <div className="mt">
      <Banner title="Top AI based companies" />
      <Helmet>
        <title>Top Companies</title>
      </Helmet>
      <div title="Company">
        <div>
          {loading ? (
            <SpinnerComponent animation="border" variant="primary" />
          ) : error ? (
            <ErrorAlertComponent error={error} />
          ) : (
            <>
              <div className="dark pb-4">
                <div className="container py-4 text-white d-flex justify-content-center align-items-center">
                  <FiBox className="me-1" size={20} />
                  <h6 className="m-0">
                    Total Companies: {filteredCompanies.length}
                  </h6>
                </div>
                <div className="container d-flex flex-wrap justify-content-center align-items-center">
                  <input
                    type="text"
                    id="search-input"
                    className="form-control"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search by company name or location"
                  />

                  <select
                    id="category-select"
                    className="form-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="All">All</option>
                    {aiCategories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="container d-flex flex-wrap justify-content-center align-items-center">
                {filteredCompanies.length > 0 ? (
                  filteredCompanies.map((company, index) => (
                    <div
                      key={index}
                      onClick={() => navigateToDetails(company.company_name)}
                      className="companyCard"
                    >
                      <div className="d-flex justify-content-between">
                        <h4 className="text-primary text-capitalize">
                          {company.company_name}{" "}
                        </h4>{" "}
                        <FiArrowUpRight
                          className="text-end text-primary"
                          size={20}
                        />
                      </div>
                      <p className="job-count">{company.job_count | 0} jobs</p>
                      <div className="d-flex align-items-center my-1">
                        <FiMapPin className="me-1 text-info" size={15} />
                        <p className="m-0">{company.hq}</p>
                      </div>
                      <div className="mt-3 d-flex justify-content-between align-items-center">
                        {company.company_logo ? (
                          <img
                            src={company.company_logo}
                            alt="logo"
                            style={{
                              width: 50,
                              height: 50,
                              padding: 4,
                              objectFit: "contain",
                              borderRadius: 100,
                              backgroundColor: "#f9f9f9",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: 45,
                              height: 45,
                              borderRadius: 100,
                              backgroundColor: "#f9f9f9",
                            }}
                          />
                        )}
                        <p className="small m-0 text-end text-capitalize text-info">
                          <FiGrid className="me-1 text-info" size={16} />{" "}
                          {company.ai_category}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-info mt-5">
                    No results found.
                  </div>
                )}
              </div>
              <div
                onClick={scrollToTop}
                className="text-primary text-center py-4"
              >
                <FiArrowUp /> Switch to Top
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Company;
