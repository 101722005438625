import React from "react";
import { FiArrowUpRight, FiBriefcase, FiGlobe, FiMapPin } from "react-icons/fi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

interface Job {
  job_title: string;
  company_name: string;
  ai_category: string;
  category: string[];
  company_logo: string;
  company_url: string;
  date_posted: string;
  department: string;
  is_remote: string;
  job_url: string;
  max_salary: number | null;
  min_salary: number | null;
  skills: string[];
  slug_url: string | null;
  work_location: string[];
}

interface JobListingProps {
  job: Job;
}

const JobListing: React.FC<JobListingProps> = ({ job }) => {
  const navigate = useNavigate();

  const navigateToDetails = (value: string) => {
    ReactGA.event({
      category: "Company Name",
      action: "Click",
      label: value,
    });
    navigate(`/company/${value}`, { state: { value: value } });
  };

  // function generateSlug(title: string): string {
  //   return title.toLowerCase().replace(/\s+/g, "-");
  // }

  const handleNavigation = (job: Job) => {
    navigate(`/jobs/${job.company_name}${job.slug_url}`, {
      state: { value: job.slug_url },
    });
  };

  return (
    <div className="list col-md-8 mx-auto">
      <div className="job-details">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <h6 className="text-capitalize text-info m-0">{job.job_title}</h6>
          <p className="text-secondary m-0 small">
            {moment(job.date_posted).fromNow()}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <span className="italic">{job.department}</span>
          <div className="d-flex align-items-center">
            <FiGlobe size={16} color="#0cf" />
            <small className="ms-1 my-2">
              {job.is_remote === "remote"
                ? "Remote"
                : job.is_remote === "hybrid"
                ? "Hybrid"
                : "On-Site"}
            </small>
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center text-primary"
          onClick={() => navigateToDetails(job.company_name)}
        >
          <div className="d-flex">
            <h6 className="text-capitalize">{job.company_name}</h6>
            <FiArrowUpRight size={14} />
          </div>
          <img
            src={job.company_logo}
            onClick={() => navigateToDetails(job.company_name)}
            alt={job.company_name}
            className="company-logo"
          />
        </div>
      </div>

      <div className="d-flex flex-wrap w-75">
        {job.skills.map((skill, index) => (
          <small
            key={index}
            className="me-1 mb-1 green px-2 py-1 badge small text-capitalize text-dark"
          >
            {skill}
          </small>
        ))}
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <div className="d-flex align-items-center my-3">
          <>
            {job.work_location && job.work_location[0] !== "" ? (
              <div className="me-2">
                <FiMapPin size={22} className="me-1" />
                <small className="text-capitalize">
                  {job.work_location.join(", ")}
                </small>
              </div>
            ) : null}
          </>
          {job.min_salary && (
            <div className="d-flex">
              <FiBriefcase size={19} className="mx-2" />
              <small>
                ${job.min_salary}k - ${job.max_salary}k
              </small>
            </div>
          )}
        </div>
        {job.slug_url ? (
          <button
            onClick={() => handleNavigation(job)}
            className="btn btn-sm btn-primary"
          >
            Apply Now
          </button>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            className="btn btn-sm btn-primary"
            href={job.job_url}
          >
            Apply Now
          </a>
        )}
      </div>
    </div>
  );
};

export default JobListing;
