import React, { useEffect, useState } from "react";
import { supabase } from "../../utils/supabaseClient";
import Banner from "../CommonComponents/Banner";
import axios from "axios";
import SpinnerComponent from "../CommonComponents/SpinnerComponent";
import ErrorAlertComponent from "../CommonComponents/ErrorAlertComponent";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { FiStar, FiX } from "react-icons/fi";
import CheckoutButton from "../CommonComponents/CheckoutButton";
import { Helmet } from "react-helmet";

type User = {
  groups: string[];
  name: string;
  uid: string;
  id: number;
  email: string;
  date_created: string;
  stripepaymentstatus: string;
  stripeplancost: number;
  stripepaymentintent: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successText, setSuccessText] = useState(false);
  const [departments, setDepartments] = useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");

  // Fetch session details from Stripe and send to API if session exists
  useEffect(() => {
    const fetchSessionDetails = async () => {
      if (sessionId) {
        try {
          // Fetch session data from Stripe
          const response = await fetch(
            `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SK}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error(`Failed to fetch session: ${response.statusText}`);
          }

          console.log(response);

          const sessionData = await response.json();
          // Ensure session data has required fields
          if (
            sessionData &&
            sessionData.amount_total &&
            sessionData.subscription &&
            sessionData.payment_status
          ) {
            const payload = {
              email: user?.email,
              updated_at: moment().format("YYYY-MM-DD"),
              stripePaymentStatus: true,
              stripePlanCost: sessionData.amount_total,
              stripePaymentId: sessionData.id,
              stripePaymentIntent: sessionData.payment_intent,
            };

            // Send the session data to the subscription API
            try {
              const apiResponse = await fetch(
                process.env.REACT_APP_USERS_PAYMENT_API!,
                {
                  method: "POST",
                  headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(payload),
                }
              );

              if (!apiResponse.ok) {
                throw new Error("Failed to subscribe with session details");
              }

              console.log("Subscription successful:", await apiResponse.json());
              fetchUserDetails();
            } catch (error) {
              console.error("Error submitting session data:", error);
            }
          }
        } catch (error) {
          console.error("Error fetching session details:", error);
        }
      }
    };

    fetchSessionDetails();
  }, [sessionId, process.env.REACT_APP_STRIPE_SK, user?.email]);

  // Fetch user details
  useEffect(() => {
    fetchUserDetails();
    fetchDepartments();
  }, []);

  const fetchUserDetails = async () => {
    const userString = localStorage.getItem("user");
    if (!userString) {
      console.log("No user data found in local storage");
      return;
    }
    const storedUser = JSON.parse(userString);
    const params = {
      email: storedUser.email,
      updated_at: new Date().toISOString(),
      name: storedUser.name,
    };
    try {
      const response = await fetch(process.env.REACT_APP_USERS_API!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    if (user?.groups) {
      setSelectedDepartments(user.groups); // Initialize selectedDepartments with user.groups
    }
  }, [user]);

  // Handle logout
  const handleLogout = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error("Logout error:", error);
      setLoading(false);
    } else {
      localStorage.removeItem("user");
      navigate("/");
      window.location.reload();
      setLoading(false);
    }
  };

  // Fetch departments list
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_DEPARTMENT_LISTING_API!
      );
      setDepartments(response.data.items);
    } catch (error) {
      setError("Error fetching data");
      console.error("Error fetching departments:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle department selection
  const handleDepartmentChange = (department: string) => {
    setSelectedDepartments((prevSelected) =>
      prevSelected.includes(department)
        ? prevSelected.filter((d) => d !== department)
        : [...prevSelected, department]
    );
  };

  // Handle subscription to selected departments
  const handleSubscription = async () => {
    setLoading(true);
    const payload = {
      email: user && user.email,
      updated_at: moment().format("YYYY-MM-DD"),
      groups: selectedDepartments,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_USERS_SUBSCRIPTION_API!,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to subscribe to groups");
      }
      setSuccessText(true);
    } catch (err) {
      console.log("Error during subscription submission:", err);
      setLoading(false);
      setError("Failed to submit the data");
      // Show error toast/alert
      alert("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSuccessText(false); // This will hide the success message when the close icon is clicked
  };

  return (
    <div className="mt">
      <Banner title="Dashboard" />
      <Helmet>
        <title>My Dashboard : AiJobster</title>
      </Helmet>
      {loading ? (
        <SpinnerComponent animation="border" variant="primary" />
      ) : error ? (
        <ErrorAlertComponent error={error} />
      ) : (
        <>
          {user && (
            <div className="container vh-50 flex-column mt-2">
              <div className="mt-4">
                <div className="d-flex justify-content-center align-items-center">
                  <h4 className="text-primary">Hi, {user.name}</h4>
                  {successText && (
                    <div className="d-flex align-items-center text-primary">
                      <h6 className="text-success mx-2 m-0">
                        Successfully Updated
                      </h6>
                      <FiX
                        color="tomato"
                        size={14}
                        onClick={handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                </div>
                <p className="text-secondary text-center">
                  Your registered email is: {user.email}
                </p>
              </div>
              <div className="w-50 mx-auto card p-5">
                <h5 className="text-primary text-center mb-5">
                  Subscription Plans
                </h5>
                <div className="mb-4">
                  <div className="ms-3">
                    <b className="d-flex align-items-center text-primary">
                      <FiStar color="gold" size={20} className="me-2" />
                      Discover Hidden Jobs{" "}
                    </b>
                    <p>
                      AIjobster scans the internet every day to find job
                      openings that aren’t posted on traditional job boards like
                      LinkedIn or Indeed.
                    </p>
                  </div>
                  <div className="ms-3">
                    <b className="d-flex align-items-center text-primary">
                      <FiStar color="gold" size={20} className="my-2 me-2" />
                      Get Ahead of the Competition
                    </b>
                    <p>
                      We identify new job listings within 24 hours of being
                      posted, giving you the chance to apply before others even
                      know they’re available. Early applications can help you
                      stand out in a crowded job market.
                    </p>
                  </div>
                  <div className="ms-3">
                    <b className="d-flex align-items-center text-primary">
                      <FiStar color="gold" size={20} className="my-2 me-2" />
                      Stay Informed with Daily Updates
                    </b>
                    <p>
                      Receive curated job alerts directly to your inbox every
                      day, so you never miss out on new opportunities. Our
                      intelligent system ensures you only get the most relevant
                      positions based on your profile and preferences.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                    <h6 className="text-primary m-0">Weekly Plan</h6>
                    <p className="text-muted m-0">$2 per week</p>
                    <CheckoutButton plan="weekly" />
                  </div>
                  <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                    <h6 className="text-primary m-0">Monthly Plan</h6>
                    <p className="text-muted m-0">$7 per month</p>
                    <CheckoutButton plan="monthly" />
                  </div>

                  <div className="tag  d-flex w-100 justify-content-between align-items-center px-4 text-center shadow-sm">
                    <h6 className="text-primary m-0">Yearly Plan</h6>
                    <p className="text-muted m-0">$77 per year</p>
                    <CheckoutButton plan="yearly" />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  {user.stripepaymentstatus && (
                    <p className="mt-3">
                      You have subscribed to{" "}
                      {user.stripepaymentstatus ? "paid" : null}
                      <span className="text-success ms-2">
                        ${(user.stripeplancost / 100).toFixed(2)} Plan
                      </span>
                    </p>
                  )}
                  <hr className="mx-auto w-25 mt"></hr>
                  <h6 className="mt">
                    Select Groups you want to Subscribe to:
                  </h6>
                  <ul className="d-flex p-0 flex-wrap align-items-center">
                    {departments
                      .filter((department) => department.trim() !== "")
                      .map((department, index) => (
                        <li className="tag" key={index}>
                          <label>
                            <input
                              disabled={!user.stripepaymentstatus}
                              type="checkbox"
                              checked={selectedDepartments.includes(department)}
                              onChange={() =>
                                handleDepartmentChange(department)
                              }
                            />
                            <span className="small ms-2">{department}</span>
                          </label>
                        </li>
                      ))}
                  </ul>
                  <div className="my-4">
                    <button
                      className="btn btn-sm btn-primary px-4"
                      onClick={handleSubscription}
                      disabled={!user.stripepaymentstatus}
                    >
                      Subscribe to Alerts
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-2 px-4"
                      onClick={handleLogout}
                    >
                      Sign Out
                    </button>
                  </div>
                  <h6 className="sm text-secondary pb-5">
                    **For cancellation, please email to aijobsterworks@gmail.com
                  </h6>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Login;
