import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import reddit from "../../images/reddit.png";
import hackernews from "../../images/Hackernews.png";
import startupstash from "../../images/startupstash.svg";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.avif";
import {
  jobTypes,
  jobSkills,
  locationList,
  aiDirs,
} from "../../utils/dataSource";
import SpinnerComponent from "./SpinnerComponent";
import ErrorAlertComponent from "./ErrorAlertComponent";

interface DepartmentData {
  heading: string;
  items: string[];
  count: number;
}

interface FooterProps {
  showFilter?: boolean;
}

const Footer: React.FC<FooterProps> = ({ showFilter = true }) => {
  const [departments, setDepartments] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchDepartments = async () => {
      try {
        const response = await axios.get<DepartmentData>(
          process.env.REACT_APP_DEPARTMENT_LISTING_API!
        );
        setDepartments(response.data.items);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching departments:", error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  return (
    <footer className="bg mt-5 text-white">
      <div className="container py-5">
        {loading ? (
          <SpinnerComponent animation="border" variant="primary" />
        ) : error ? (
          <ErrorAlertComponent error={error} />
        ) : (
          showFilter && (
            <div className="row">
              <div className="col-md-4">
                <h5 className="mb-3">Search by Departments</h5>
                <ul className="d-flex row p-0 flex-wrap align-items-center">
                  {departments
                    .filter((department) => department.trim() !== "") // Filter out empty values
                    .map((department, index) => (
                      <li className="tag" key={index}>
                        <Link
                          to={{
                            pathname: "/",
                            search: `?department=${department}`,
                          }}
                          className="text-white"
                        >
                          <span className="small">{department}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>

              <div className="col-md-2">
                <h5 className="mb-3">Search by Category</h5>
                <ul className="d-flex p-0 flex-wrap align-items-center">
                  {jobTypes.map((item, index) => (
                    <li className="tag" key={index}>
                      <Link
                        to={{
                          pathname: index === 0 ? "/remote_jobs" : "/",
                          search:
                            index !== 0 ? `?search=${item.slug}` : undefined,
                        }}
                        className="text-white"
                      >
                        <span className="small">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-2">
                <h5 className="mb-3">Search by Skills</h5>
                <ul className="d-flex p-0 flex-wrap align-items-center">
                  {jobSkills.map((item, index) => (
                    <li className="tag" key={index}>
                      <Link
                        to={{
                          pathname: "/",
                          search: `?search=${item.slug}`,
                        }}
                        className="text-white"
                      >
                        <span className="small">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-2">
                <h5 className="mb-3">Search by Location</h5>
                <ul className="d-flex p-0 flex-wrap align-items-center">
                  {locationList.map((item, index) => (
                    <li className="tag" key={index}>
                      <Link
                        to={{
                          pathname: "/",
                          search: `?search=${item.slug}`,
                        }}
                        className="text-white"
                      >
                        <span className="small">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-2">
                <h5 className="mb-3">AI Directories</h5>
                <ul className="d-flex p-0 flex-wrap align-items-center">
                  {aiDirs.map((item, index) => (
                    <li className="tag" key={index}>
                      <a href={item.slug} target="_blank" rel="noreferrer">
                        <span className="small">{item.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )
        )}
      </div>
      <div className="d-flex justify-content-center">
        <a
          href="https://www.reddit.com/r/aijobster"
          target="_blank"
          rel="noreferrer"
        >
          <img src={reddit} className="social-logo" alt="social-links-reddit" />
        </a>
        <a href="https://x.com/aijobster" target="_blank" rel="noreferrer">
          <img
            src={twitter}
            className="social-logo"
            alt="social-links-twitter"
          />
        </a>
        {/*<a
          href="https://hn.matthewblode.com/show"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={hackernews}
            className="social-logo"
            alt="social-links-hackernews"
          />
        </a>
        <a href="https://startupstash.com/" target="_blank" rel="noreferrer">
          <img
            src={startupstash}
            className="social-logo"
            alt="social-links-startupstash"
          />
        </a>
        <a
          href="https://www.facebook.com/search/aijobster"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={facebook}
            className="social-logo"
            alt="social-links-facebook"
          />
        </a>*/}
      </div>
      <div className="text-center flex-column d-flex mt-5 py-5">
        <small>© 2024 • AI Jobster • Made in California</small>
        <small className="mt-2">
          Designed with <FaHeart color="tomato" /> by
          <a
            target="_blank"
            rel="noreferrer"
            className="text-decoration-underline mx-1"
            href="https://codeindica.netlify.app"
          >
            codeindica
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
