import React, { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { supabase } from "../../utils/supabaseClient";

const NavMenu: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, setUser } = useUser();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleLogin = async () => {
    if (user) {
      navigate("/dashboard");
    } else {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
      });

      if (error) {
        console.error("Login error:", error);
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const hardRefresh = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle: React.CSSProperties = {
    backgroundColor: scrollPosition > 100 ? "#1b1726" : "#1b1b2a",
    transition: "background-color 0.3s ease",
    padding: "20px",
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 1000,
  };

  const getInitials = (name: string | undefined): string => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
    return (firstName[0] + (lastName ? lastName[0] : "")).toUpperCase();
  };

  return (
    <div className="nav-menu">
      {/* Side drawer */}
      <div className={`drawer ${isMenuOpen ? "open" : ""}`}>
        <div className="drawer-content">
          <ul>
            <li className="my-3">
              <Link to="/remote_jobs" onClick={toggleMenu}>
                Remote Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/engineering_jobs" onClick={toggleMenu}>
                Engineering Jobs
              </Link>
            </li>
            <li className="my-3">
              <Link to="/company" onClick={toggleMenu}>
                Top Companies
              </Link>
            </li>
            <li className="my-3">
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li className="my-3">
              <Link to="/blog" onClick={toggleMenu}>
                Blog
              </Link>
            </li>
            {user && user.user_metadata ? (
              <div
                className="d-flex ms-2 align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div
                  className="profile-initials"
                  onClick={handleLogin}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#4169e1",
                    width: "35px",
                    height: "35px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {getInitials(
                    user.user_metadata.full_name || user.user_metadata.email
                  )}
                </div>
              </div>
            ) : (
              <li
                onClick={handleLogin}
                style={{ cursor: "pointer" }}
                className="mx-2 text-white"
              >
                <p>Login</p>
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* Web Header */}
      <div
        style={headerStyle}
        className="bg fixed-top w-100 d-flex align-items-center p-2 web-menu"
      >
        <Link to="/" className="d-flex align-items-center col-md-2">
          <img src={logo} alt="AI Jobster" className="logo" />
          <h5 className="mx-1 m-0 text-white fw-bolder">AI Jobster</h5>
        </Link>
        <div className="col-md-10 d-flex justify-content-end align-items-center hide-menu">
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/remote_jobs">Remote Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/engineering_jobs">Engineering Jobs</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/company">Top Companies</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/about">About</Link>
          </li>
          <li onClick={hardRefresh} className="ms-2">
            <Link to="/blog">Blog</Link>
          </li>
          {user && user.user_metadata ? (
            <div
              className="d-flex ms-2 align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div
                className="profile-initials"
                onClick={handleLogin}
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#4169e1",
                  width: "35px",
                  height: "35px",
                  margin: "0 10px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {getInitials(
                  user.user_metadata.full_name || user.user_metadata.email
                )}
              </div>
            </div>
          ) : (
            <li
              onClick={handleLogin}
              style={{ cursor: "pointer" }}
              className="mx-3 mt-3 text-white"
            >
              <p>Login</p>
            </li>
          )}
        </div>
      </div>

      {/* Menu toggle button */}
      <div className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
      </div>
    </div>
  );
};

export default NavMenu;
