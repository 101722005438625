// src/supabaseClient.ts
import { createClient, SupabaseClient } from "@supabase/supabase-js";

// Use environment variables for Supabase URL and anon key
const SUPABASE_URL: string = "https://yakrxukwlxvtfjjmpqrd.supabase.co";
const SUPABASE_ANON_KEY: string =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlha3J4dWt3bHh2dGZqam1wcXJkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzAyNjYyNzgsImV4cCI6MjA0NTg0MjI3OH0.rvF0AV3u3Iz74eICixLXpwr9OxRFcZ32V7WDdJppGv8";

// Create Supabase client
export const supabase: SupabaseClient = createClient(
  SUPABASE_URL,
  SUPABASE_ANON_KEY
);
